import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Layout from '@/components/layouts/auth.vue';
import { UserLoginRequest } from '@/types/request/auth-request.types';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import { AuthResponse } from '@/types/response/auth-response.types';

const title = 'login.title';

@Component({
  name: 'Login',
  page: {
    title
  },
  components: {
    Layout,
    ValidationObserver,
    BTextInputValidation
  },
})
export default class Login extends Vue {
  login = '';
  password = '';
  tryingToLogIn = false;

  @Action('signIn', { namespace: 'auth' })
  signIn!: (params: UserLoginRequest) => Promise<AuthResponse>;

  tryToLogIn() {
    if (!this.tryingToLogIn) {
      this.tryingToLogIn = true;

      return this.signIn({ email: this.login, password: this.password })
        .then(response => {
          this.tryingToLogIn = false;
          if (response.token) {
            if (this.$route.query.redirectFrom) {
              this.$router.push({ path: this.$route.query.redirectFrom as string }).catch();
            } else {
              this.$router.push({ name: 'home' }).catch();
            }
          }
        })
        .catch(() => {
          this.tryingToLogIn = false;
        });
    }
  }
}
